.contact-us {
  padding: 70px 0;
  text-align: left;
  .heading {
    margin-top: 53px;
    div {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}
.contact-us-map {
    padding: 70px 0;
  iframe {
    height: 500px;
    width: 100%;
  }

  overflow: hidden;
}
