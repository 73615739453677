.term-n-condition {
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  .reading-content {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .bottom-row-step{
      margin-top: 35px;
  }
}
