.hero-slider {
  padding: 70px 0px;
  @media only screen and (max-width: 768px) {
    padding: 35px 0px;
  }
  .item {
    display: flex;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    .text-content {
      width: 60%;
      @media only screen and (max-width: 768px) {
        width: 80%;
      }
      h2 {
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 22px;
        @media only screen and (max-width: 768px) {
          font-size: 24px;
        }
      }
      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: $secondary-text-color;
        margin-bottom: 30px;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
      button {
        @extend %primary-button;
        font-size: 14px;
        padding: 15px 50px;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
          padding: 12px 40px;
        }
      }
    }
    .image-content {
      width: 40%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .slick-prev {
    left: -35px;
  }
  .slick-next {
    right: -35px;
  }
}
.service-card {
  padding: 70px 0px;
  @media only screen and (max-width: 768px) {
    padding: 35px 0px;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .card-block {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    img {
      width: 70px;
      height: 70px;
      @media only screen and (max-width: 768px) {
        width: 56px;
        height: 56px;
      }
    }
    h3 {
      margin-top: 12px;
      font-size: 16px;
      font-weight: bold;
      color: $primary-text-color;
      margin-bottom: 10px;
      @media only screen and (max-width: 768px) {
        margin-top: 6px;
      }
    }
    p {
      font-size: 16px;
      font-weight: normal;
      color: $secondary-text-color;
      margin-bottom: 10px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 6px;
      }
    }
    a {
      font-size: 13px;
      font-weight: bold;
      color: $primary-text-color;
      text-transform: uppercase;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    &:hover {
      box-shadow: 6px 5px 15px 0 #dddddd;
      h3 {
        color: $primary-color;
      }
      a {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}
.our-process {
  background-color: #f6f8fa;
  padding: 70px 0px;
  @media only screen and (max-width: 768px) {
    padding: 35px 0px;
  }
  img {
    text-align: center;
  }
  h4 {
    @extend %title;
  }
  p {
    @extend %subtitle;
  }
  ul {
    list-style: none;
    margin-top: 32px;
    padding: 0;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
    li {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
      padding-left: 35px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 16px;
      }
      &::before {
        content: attr(srno);
        display: inline-block;

        background-color: #0000ff23;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        text-align: center;
        color: $primary-color;
        position: absolute;
        left: 0;

        @media only screen and (max-width: 768px) {
          width: 17px;
          height: 17px;
          font-size: 11px;
        }
      }
    }
  }
}
.testimonials {
  padding: 70px 0px;
  @media only screen and (max-width: 768px) {
    padding: 35px 0px;
  }
  h5 {
    @extend %title;
  }
  p {
    @extend %subtitle;
  }
  .testimonials-slider {
    .item {
      .inner-card {
        margin: 0 30px 25px 30px;
        box-shadow: 6px 5px 15px 0 #dddddd;
        background-color: #ffffff;
        padding: 20px 32px;
        @media only screen and (max-width: 768px) {
          margin: 15px 15px;
          padding: 20px;
        }
        .testi-icon {
          font-family: "Playfair Display", serif;
          font-size: 48px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 10px;
          letter-spacing: normal;
          color: $primary-color;
          @media only screen and (max-width: 768px) {
            font-size: 31px;
          }
        }
        .message {
          font-size: 16px;
          color: $secondary-text-color;
          @media only screen and (max-width: 768px) {
            font-size: 10px;
          }
        }
        .name {
          color: black;
          font-weight: bold;
          @media only screen and (max-width: 768px) {
            font-size: 10px;
          }
        }
      }
    }
  }
  .slide-count {
    font-weight: bold;
    text-align: center;
  }
  .slick-next,
  .slick-prev {
    bottom: -33px;
    top: unset;
    @media only screen and (max-width: 768px) {
    }
  }
  .slick-prev {
    left: 40%;
  }
  .slick-next {
    right: 40%;
  }
}
.faq {
  padding: 70px 0px;
  @media only screen and (max-width: 768px) {
    padding: 35px 0px;
  }
  h6 {
    @extend %title;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .faq-card {
    border-radius: 5px;
    box-shadow: 6px 5px 15px 0 #dddddd;
    background-color: #ffffff;
    padding: 40px;
    @media only screen and (max-width: 768px) {
      padding:15px 10px
    }
    h5 {
      position: relative;
      
      button {
        @media only screen and (max-width: 768px) {
          font-size: 11px;
          padding-left: 0px;
          padding-right: 0px;
        }
        &:focus {
          text-decoration: none;
        }
        width: 100%;
        text-align: left;
        font-weight: bold;
        border-radius: unset;
        color: black;
        .accord-icon {
          width: 16px;
          height: 16px;
          float: right;
          @media only screen and (max-width: 768px) {
            width: 13px;
          }
        }
        .minus {
          display: block;
        }
        .plus {
          display: none;
        }
        &.collapsed {
          .minus {
            display: none;
          }
          .plus {
            display: block;
          }
        }
      }
    }
    .card-body {
      color: $secondary-text-color;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
        padding:0px !important
      }
    }
  }
}
