label {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: $secondary-text-color;
}
.custom-select-control {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid $primary-text-color;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 16px;
  font-weight: 500;
  color: $primary-text-color;
  &:focus {
    box-shadow: none;
    border-color: unset;
    color: unset;
  }
}
%primary-button,
.primary-button {
  border: unset;
  background: #0000ff;
  border-radius: 24px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: center;
  color: #ffffff;
  padding: 10px 25px;
  text-transform: uppercase;
  cursor: pointer;
}

.custom-text-container {
  margin-top: 30px;
  position: relative;
  height: 60px;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 20px 0px 0px 0px;
    outline: none;
    border-radius: 0px;
    color: $primary-text-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.6px;
  }
  label {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid lightgray;
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      left: 0px;
      bottom: -1px;
      width: 100%;
      border-bottom: 1px solid $primary-text-color;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
    }
    .content-name {
      position: absolute;
      bottom: 5px;
      left: 0px;
      transition: all 0.3s ease;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      letter-spacing: 0.6px;
      color: $secondary-text-color;
    }
  }
  input:focus + .label-name .content-name,
  input:valid + .label-name .content-name {
    transform: translateY(-125%);
    color: $secondary-text-color;
  }
  input:focus + .label-name::after,
  input:valid + .label-name::after {
    transform: translateY(0%);
  }
}
.form-check{
  margin-top: 25px;
  label{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: $primary-text-color;
    text-transform: capitalize;
  }
}
%title,
.title {
  font-size: 48px;
  font-weight: bold;
  color: black;
  text-transform: capitalize;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
}
%heading,
.heading {
  font-size: 16px;
  font-weight: 500;

  color: #000000;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
%subtitle,
p {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0px;

  color: $secondary-text-color;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.info-text{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: $secondary-text-color;
}
section {
  padding: 70px 0;
}
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  color: $primary-color;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
