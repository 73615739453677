.about-us {
  .hero-about-us {
    padding: 70px 0;
    text-align: center;
    h1 {
      margin-bottom: 42px;
    }
    p {
      width: 60%;
      margin: 0 auto;
    }
  }
  .our-team {
    padding: 70px 0;
    .team-slider {
      .custom-card-team {
        box-shadow: 6px 5px 15px 0 #dddddd;
        background-color: #ffffff;
        margin: 20px;
        text-align: center;
        padding: 22px;
        text-align: center;
        img {
          width: 100px;
          height: 100px;
          border-radius: 100%;
          margin: 0 auto;
        }
        h3 {
          margin-top: 13px;
          font-size: 16px;
          font-weight: bold;

          text-align: center;
          color: #222222;
          margin-bottom: 0px;
        }
        p {
          font-size: 14px;

          text-align: center;
          color: #222222;
        }
      }
    }
  }
  .choose-us {
      background-color: $light-grey;
      padding: 70px 0;
    text-align: center;
    .subtitle{
        margin-bottom: 40px;
    }
    .panel-custom {
      box-shadow: 6px 5px 15px 0 #dddddd;
      background-color: #ffffff;
      .bottom-block{
          padding: 14px;
      }
    }
  }
}
