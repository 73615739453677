.body {
  font-family: "DM Sans", sans-serif;
  color: #727589;


}
@media only screen and (max-width: 768px) {
 .container{
  padding-right: 30px !important;
  padding-left:30px !important ;
 }   
}
button:focus {
  box-shadow: none;
  border: none;
  border-color: unset;
  outline: unset;
}
$primary-color: #0000ff;
$primary-text-color: #34374c;
$secondary-text-color: #727589;
$light-grey:#f6f8fa;
