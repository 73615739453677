.custom-navbar {
  padding: 20px 0px;
  .navbar-brand {
    object-fit: contain;
    font-size: 28px;
    font-weight: bold;
    color: #000000;
  }
  .nav-item {
    a {
      margin: 0 10px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #34374c;
    }
    .primary-button {
      @extend %primary-button;
      padding: 10px 20px !important;
    }
  }
}
.footer-nav {
  padding: 70px 0px 30px 70px;
  background-color: #f6f8fa;
  @media only screen and (max-width: 768px) {
    padding: 30px 0px;
  }
  p {
    color: $primary-text-color;
    font-weight: 500;
    margin-bottom: 0px;
  }
  ul {
    li > a {
      color: $primary-text-color;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: $primary-text-color;
    margin-bottom: 14px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      margin-top: 18px;
    }
  }
  .social-share {
    display: flex;
    justify-content: space-around;
    a {
      img {
        width: 40px;
        height: 40px;
        @media only screen and (max-width: 768px) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .copyright {
    text-align: center;
    margin-top: 60px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
    color: black;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
}
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #0000ff;
  z-index: 1111;
  height: 100%;
  ul {
    list-style: none;
    padding: 0;
    text-align: center;
    li {

      margin-bottom: 25px;
      a {
       
        font-weight: bold;
        font-size: 16px;
        color: white;
      
        text-align: center;
      }
    }
    .close-nav {
      margin-top: 80px;
      cursor: pointer;
    }
  }
}
